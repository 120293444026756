<template>
    <div>
        <h2><strong>优惠券加量包</strong></h2>
        <user-info></user-info>
        <div class="coupon-List">
            <coupon-package v-for="(couponpackage,index) in sellcouponlist" :couponpackage='couponpackage' :key="index"></coupon-package>
        </div>
    </div>    
</template>
<script>

import CouponPackage from "@/components/CouponPackage";
import userInfo from "@/views/usercenter/components/userInfo.vue";
import {getSellCouponlist} from "@/api/manage"

export default {
  components: {

    CouponPackage,
    userInfo
  },
  data() {
    return {
        sellcouponlist:[],
    };
  },
  computed: {

  },
  mounted() {
   this.getCouponlist()
  },
  methods:{
      getCouponlist(){
       getSellCouponlist().then(({ data }) => {
        //  console.log(data)
        this.sellcouponlist=data
      });
   }
  },
};
</script>

<style lang="less" scoped>
h2{
    border-bottom: 1px solid #ddd;
    padding-bottom:20px;
    strong{
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 3px;
            width: 120px;
            left: 0;
            bottom: -25px;
            background-image: linear-gradient(to right, #0049ee , #36bcf0);
    
        }
    }
}
.coupon-List{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}
</style>