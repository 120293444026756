<template>
  <div class="coupon-item coupon-item-d">
    <div class="c-type">
      <div class="c-price">
        <p><strong>{{couponpackage.acname}}</strong></p>
        <div>
          <span class="num">{{couponpackage.couponvalue}}</span><span>元</span>
          <span class="cha">×</span>
          <span class="num">{{couponpackage.coupongetcount}}</span><span>张</span>
        </div>
      </div>
      <div style="display: flex;flex-direction: column;align-items: center;flex-wrap: wrap;justify-content: center;">
        <div class="c-limit">{{couponpackage.threshold_notice}}</div>
        <div class="c-limit">至购买起{{couponpackage.limitday}}天有效</div>
      </div>
    </div>

    <div class="c-msg">
      <div class="c-range">
        <div class="range-item" style="display: flex;justify-content: center;align-items: center;">
            <div>￥ <span class="getprice">{{couponpackage.getprice}} </span></div>
            <div style="display: flex;flex-wrap: wrap;flex-direction: column;">
              <span class="zk">{{discount(couponpackage.getprice,couponpackage.original_price)}}折抢购 </span>
              <span class="original_price">￥{{couponpackage.original_price}} </span>
            </div>
        </div>

        <!-- <div class="range-item">
          <span class="label">时效：</span>
          <span class="txt">至购买起{{couponpackage.limitday}}天有效</span>
        </div> -->
      </div>

      <div class="op-btns">
        <a class="btn" @click="buyCoupon(couponpackage.activityid)"><span class="txt">立即购买</span><b></b></a>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.num{
  color: #fc592e;
  font-weight: bold;
  font-size: 22px;
  padding: 0 3px;
}
.cha{
  margin: 0 4px;
}
.c-msg{
  .getprice{
    font-size: 30px;
    color: #fd5a2f;
    margin-right: 6px;
  }
  .original_price{
    text-decoration:line-through;
  }
}
.zk{
  padding: 2px 8px;
  font-size: 12px;
    border-radius: 4px;
    background-image: linear-gradient(to right, #ebc69a , #feead1);
}
</style>
<script>
import wxPayModal from "@/components/wxPayModal";
import defaultModal from '@/components/defaultModal'
import { payCoupon } from '@/api/pay'
export default {
  props: {
    couponpackage: {
      type: Object,
      default() {
        return [];
      },
    },
    
  },
  data() {
    return {};
  },

  methods: {
    discount(getprice,original_price){
				return ((getprice/original_price)*10).toFixed(1)
			},
      buyCoupon(activityid) {
      console.log(activityid)
      let _this = this;
      let parameter = {
					// xeucode:10086688,
					activityid:activityid,
          paytype:2,
				}

				payCoupon(parameter).then(data => {
          console.log(data)
          if(data.code==200){
      _this.$dialog(
        wxPayModal,
        // component props
        {
          payData: data.data,
          on: {
            ok() {
              console.log("modal ok 回调");
            },
            cancel() {
              console.log("cancel 回调");
            },
            close() {
              console.log("modal close 回调");
            },
          },
        },
        // modal props
        {
          width: 400,
          footer:"",//确定取消不要
          centered: true,
          maskClosable: false,
        }
      );
          }else{
            _this.$dialog(
        defaultModal,
        // component props
        {
          msg: '会员过期，无法购买优惠券,是否续费会员',
          on: {
            ok() {
              console.log("modal ok 回调");
              _this.$router.push({
                path: '/manage/user/',
              })
            },
            cancel() {
              console.log("cancel 回调");
            },
            close() {
              console.log("modal close 回调");
            },
          },
        },
        // modal props
        {
          width: 500,
          // footer:"",//确定取消不要
          centered: true,
          maskClosable: false,
        }
      );
          }
				}).catch(() => {
				
				
				})
      return;
      
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.coupon-item {
  width: 290px;
  margin: 14px 10px 20px;
  .c-type {
    position: relative;
    width: 100%;
    height: 180px;
    padding: 16px 0 10px;
    overflow: hidden;
    color: #000;
    text-align: center;
    // background-color: #1ca3f2;
    background: url(../../assets/img/coupon.png) no-repeat;
    background-size: 100% 100%;
  }
  .c-price {
    position: relative;
    margin: 0 0 5px;
    text-align: center;
    font-weight: 400;
    strong{
      font-size: 20px;
    }
  }
  .c-limit {
    
    padding: 2px 8px;
    border-radius: 4px;
    background-image: linear-gradient(to right, #ebc69a , #feead1);
    overflow: hidden;
    margin: 0 0 5px;
    font-size: 12px;
    display: inline-block;
  }
  .c-time {
    color: #ddeefe;
  }
  .c-type-top {
    position: absolute;
    width: 40px;
    height: 13px;
    top: 0;
    left: 50%;
    margin-left: -23px;
    background: url(//misc.360buyimg.com/user/myjd-2015/css/i/coupon20160715.png)
      0 -272px no-repeat;
  }
  .c-type-bottom {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: url(//misc.360buyimg.com/user/myjd-2015/css/i/coupon20160715.png) -3px -296px
      no-repeat;
    background-position: 2px -302px;
  }
  .c-msg {
    height: 158px;
    font-size: 14px;
    // line-height: 30px;
    padding: 30px 10px 0;
    background: #f9f9f9;
    overflow: visible;
    text-align: center;
    box-shadow: 1px 3px 11px #e0e0e0;
  }
  .range-item {
    display: inline-block;
    display: block;
    margin: 0 0 10px;
    &:after {
      content: "";
      height: 0;
      line-height: 0;
      display: block;
      visibility: hidden;
      clear: both;
    }
    .label {
      float: left;
      width: 60px;
      display: inline;
      text-align: right;
    }
    .txt {
      float: left;
      width: 144px;
      color: #999;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .op-btns {
    margin-top: 30px;
    text-align: center;
    .btn {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: 119px;
      height: 31px;
      line-height: 31px;
      border: 1px solid #1ca3f2;
      color: #1ca3f2;
      border-radius: 15px;
      vertical-align: middle;
      transition: all 0.4s;
      &:hover {
        color: #fff;
        background: #1ca3f2;
      }
    }
  }
  &.coupon-item-dgray {
    .c-type {
      background-color: #c3c3c3;
      .c-price {
        .type {
          color: #868686;
        }
      }
      .c-time {
        color: #868686;
      }
    }
    .c-type-bottom {
      background-position: 0 -316px;
    }
    .op-btns {
      display: none;
    }
  }
}
</style>